import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import rough from "roughjs/bundled/rough.esm";
import getStroke from "perfect-freehand";
import "./draw.css";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";


const generator = rough.generator();

const canvasStyle = {
    position: "absolute",
    zIndex: 1,
    left: `15%`,
    top: `15%`,
    border: "1px solid black"
};

const createElement = (id, x1, y1, x2, y2, type) => {
    switch (type) {
        case "line":
        case "rectangle":
            const roughElement = type === "line"
                ? generator.line(x1, y1, x2, y2)
                : generator.rectangle(x1, y1, x2 - x1, y2 - y1);
            return { id, x1, y1, x2, y2, type, roughElement };
        case "pencil":
            return { id, type, points: [{ x: x1, y: y1 }] };
        case "text":
            return { id, type, x1, y1, x2, y2, text: "" };
        default:
            throw new Error(`Type not recognised: ${type}`);
    }
};

const useHistory = initialState => {
    const [index, setIndex] = useState(0);
    const [history, setHistory] = useState([initialState]);

    const setState = (action, overwrite = false) => {
        const newState = typeof action === "function" ? action(history[index]) : action;
        if (overwrite) {
            const historyCopy = [...history];
            historyCopy[index] = newState;
            setHistory(historyCopy);
        } else {
            const updatedState = [...history].slice(0, index + 1);
            setHistory([...updatedState, newState]);
            setIndex(prevState => prevState + 1);
        }
    };

    const undo = () => index > 0 && setIndex(prevState => prevState - 1);
    const redo = () => index < history.length - 1 && setIndex(prevState => prevState + 1);

    return [history[index], setState, undo, redo];
};

const getSvgPathFromStroke = stroke => {
    if (!stroke.length) return "";

    const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
            return acc;
        },
        ["M", ...stroke[0], "Q"]
    );

    d.push("Z");
    return d.join(" ");
};

const drawElement = (roughCanvas, context, element) => {
    switch (element.type) {
        case "line":
        case "rectangle":
            roughCanvas.draw(element.roughElement);
            break;
        case "pencil":
            const stroke = getSvgPathFromStroke(getStroke(element.points));
            context.fill(new Path2D(stroke));
            break;
        case "text":
            context.textBaseline = "top";
            context.font = "24px sans-serif";
            context.fillText(element.text, element.x1, element.y1);
            break;
        default:
            throw new Error(`Type not recognised: ${element.type}`);
    }
};

const adjustmentRequired = type => ["line", "rectangle"].includes(type);

const usePressedKeys = () => {
    const [pressedKeys, setPressedKeys] = useState(new Set());

    useEffect(() => {
        const handleKeyDown = event => {
            setPressedKeys(prevKeys => new Set(prevKeys).add(event.key));
        };

        const handleKeyUp = event => {
            setPressedKeys(prevKeys => {
                const updatedKeys = new Set(prevKeys);
                updatedKeys.delete(event.key);
                return updatedKeys;
            });
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    return pressedKeys;
};

const Draw = ({ handleSaveDrawing }) => {

    console.log(handleSaveDrawing); // Should log the function definition
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const [elements, setElements, undo, redo] = useHistory([]);
    const [action, setAction] = useState("none");
    const [tool, setTool] = useState("rectangle");
    const [selectedElement, setSelectedElement] = useState(null);
    const [panOffset, setPanOffset] = React.useState({ x: 0, y: 0 });
    const [startPanMousePosition, setStartPanMousePosition] = React.useState({ x: 0, y: 0 });
    const textAreaRef = useRef();
    const pressedKeys = usePressedKeys();


    const saveDrawingAsPNG = async () => {
        // 1. Get the canvas element
        const canvas = document.getElementById("canvas");

        // 2. Check if canvas element exists
        if (!canvas) {
            console.error("Canvas element not found. Unable to save drawing.");
            return;
        }

        // 3. Get image data from the canvas
        const imgData = canvas.toDataURL("image/png");

        // 4. Create a link to download the image
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "drawing.png"; // Set default filename

        await handleSaveDrawing(imgData);

        // 5. Simulate a click on the link to trigger download
        link.click();
    };

    useLayoutEffect(() => {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        const roughCanvas = rough.canvas(canvas);

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.save();
        context.translate(panOffset.x, panOffset.y);

        elements.forEach(element => {
            if (action === "writing" && selectedElement.id === element.id) return;
            drawElement(roughCanvas, context, element);
        });
        context.restore();
    }, [elements, action, selectedElement, panOffset]);

    useEffect(() => {
        const undoRedoFunction = event => {
            if ((event.metaKey || event.ctrlKey) && event.key === "z") {
                if (event.shiftKey) {
                    redo();
                } else {
                    undo();
                }
            }
        };

        document.addEventListener("keydown", undoRedoFunction);
        return () => {
            document.removeEventListener("keydown", undoRedoFunction);
        };
    }, [undo, redo]);

    useEffect(() => {
        const panFunction = event => {
            setPanOffset(prevState => ({
                x: prevState.x - event.deltaX,
                y: prevState.y - event.deltaY,
            }));
        };

        document.addEventListener("wheel", panFunction);
        return () => {
            document.removeEventListener("wheel", panFunction);
        };
    }, []);

    useEffect(() => {
        const textArea = textAreaRef.current;
        if (action === "writing") {
            setTimeout(() => {
                textArea.focus();
                textArea.value = selectedElement.text;
            }, 0);
        }
    }, [action, selectedElement]);

    const updateElement = (id, x1, y1, x2, y2, type, options) => {
        const elementsCopy = [...elements];

        switch (type) {
            case "line":
            case "rectangle":
                elementsCopy[id] = createElement(id, x1, y1, x2, y2, type);
                break;
            case "pencil":
                if (elementsCopy[id].points) {
                    elementsCopy[id].points.push({ x: x2, y: y2 });
                } else {
                    elementsCopy[id].points = [{ x: x1, y: y1 }];
                }
                break;

            case "text":
                const textWidth = document
                    .getElementById("canvas")
                    .getContext("2d")
                    .measureText(options.text).width;
                const textHeight = 24;
                elementsCopy[id] = {
                    ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
                    text: options.text,
                };
                break;
            default:
                throw new Error(`Type not recognised: ${type}`);
        }

        setElements(elementsCopy, true);
    };

    const getMouseCoordinates = event => {
        const clientX = event.clientX - panOffset.x;
        const clientY = event.clientY - panOffset.y;
        return { clientX, clientY };
    };

    // const handleMouseDown = event => {
    //     if (action === "writing") return;

    //     const { clientX, clientY } = getMouseCoordinates(event);

    //     if (event.button === 1 || pressedKeys.has(" ")) {
    //         setAction("panning");
    //         setStartPanMousePosition({ x: clientX, y: clientY });
    //         return;
    //     }


    //     if (tool === "selection") {
    //         const element = getElementAtPosition(clientX, clientY, elements);
    //         if (element) {
    //             if (element.type === "pencil") {
    //                 const xOffsets = element.points.map(point => clientX - point.x);
    //                 const yOffsets = element.points.map(point => clientY - point.y);
    //                 setSelectedElement({ ...element, xOffsets, yOffsets });
    //             } else {
    //                 const offsetX = clientX - element.x1;
    //                 const offsetY = clientY - element.y1;
    //                 setSelectedElement({ ...element, offsetX, offsetY });
    //             }
    //             setElements(prevState => prevState);

    //             if (element.type === "text") {
    //                 setAction("writing");
    //             } else {
    //                 setAction("moving");
    //             }
    //         }
    //     } else {
    //         const id = elements.length;
    //         const element = createElement(id, clientX, clientY, clientX, clientY, tool);
    //         setElements(prevState => [...prevState, element]);
    //         setSelectedElement(element);
    //         setAction(tool === "text" ? "writing" : "drawing");
    //     }
    // };

    const handleMouseDown = event => {
        if (action === "writing") return;
    
        const { clientX, clientY } = getMouseCoordinates(event);
    
        if (event.button === 1 || pressedKeys.has(" ")) {
            setAction("panning");
            setStartPanMousePosition({ x: clientX, y: clientY });
            return;
        }
    
        if (tool === "selection") {
            const element = getElementAtPosition(clientX, clientY, elements);
            if (element) {
                // Handle selection logic here
                if (element.type === "pencil") {
                    const xOffsets = element.points.map(point => clientX - point.x);
                    const yOffsets = element.points.map(point => clientY - point.y);
                    setSelectedElement({ ...element, xOffsets, yOffsets });
                } else {
                    const offsetX = clientX - element.x1;
                    const offsetY = clientY - element.y1;
                    setSelectedElement({ ...element, offsetX, offsetY });
                }
                if (element.type === "text") {
                    setAction("writing");
                } else {
                    setAction("moving");
                }
            } else {
                // If no element is selected, reset action and selected element
                setAction("none");
                setSelectedElement(null);
            }
        } else {
            // Handle other tools
            const id = elements.length;
            const element = createElement(id, clientX, clientY, clientX, clientY, tool);
            setElements(prevState => [...prevState, element]);
            setSelectedElement(element);
            setAction(tool === "text" ? "writing" : "drawing");
        }
    };
    

    const handleMouseMove = event => {
        const { clientX, clientY } = getMouseCoordinates(event);

        if (action === "drawing") {
            const index = elements.length - 1;
            const { x1, y1 } = elements[index];
            updateElement(index, x1, y1, clientX, clientY, tool);
        } else if (action === "moving") {
            const { id, type, x1, x2, y1, y2, xOffsets, yOffsets } = selectedElement;
            if (type === "pencil") {
                const newPoints = selectedElement.points.map((_, index) => ({
                    x: clientX - xOffsets[index],
                    y: clientY - yOffsets[index],
                }));
                const elementsCopy = [...elements];
                elementsCopy[id] = {
                    ...elementsCopy[id],
                    points: newPoints,
                };
                setElements(elementsCopy, true);
            } else {
                const width = x2 - x1;
                const height = y2 - y1;
                const newX2 = clientX + width - selectedElement.offsetX;
                const newY2 = clientY + height - selectedElement.offsetY;
                updateElement(id, clientX - selectedElement.offsetX, clientY - selectedElement.offsetY, newX2, newY2, type);
            }
        } else if (action === "panning") {
            const deltaX = clientX - startPanMousePosition.x;
            const deltaY = clientY - startPanMousePosition.y;
            setPanOffset(prevState => ({
                x: prevState.x + deltaX,
                y: prevState.y + deltaY,
            }));
            setStartPanMousePosition({ x: clientX, y: clientY });
        }
    };

    const handleMouseUp = event => {
        const { clientX, clientY } = getMouseCoordinates(event);

        if (selectedElement) {
            if (
                selectedElement.type === "text" &&
                clientX === selectedElement.x1 &&
                clientY === selectedElement.y1
            ) {
                setAction("writing");
                return;
            }

            const index = selectedElement.id;
            const { id, type } = selectedElement;
            if ((action === "drawing" || action === "moving") && adjustmentRequired(type)) {
                const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
                updateElement(id, x1, y1, x2, y2, type);
            }
        }

        if (action === "panning") {
            setAction("none");
        } else if (tool === "pencil") {
            const index = elements.length - 1;
            const { points } = elements[index];
            if (points.length < 2) {
                setElements(prevState => prevState.filter((_, i) => i !== index));
            }
            setAction("none");
        } else {
            setAction("none");
            setSelectedElement(null);
        }
    };

    const handleBlur = event => {
        const { id, x1, y1 } = selectedElement;
        setAction("none");
        setSelectedElement(null);
        updateElement(id, x1, y1, null, null, "text", { text: event.target.value });
    };

    const getElementAtPosition = (x, y, elements) => {
        return elements
            .map(element => ({ ...element, position: positionWithinElement(x, y, element) }))
            .find(element => element.position !== null);
    };

    const positionWithinElement = (x, y, element) => {
        const { type, x1, x2, y1, y2 } = element;
        switch (type) {
            case "line":
                const a = { x: x1, y: y1 };
                const b = { x: x2, y: y2 };
                const c = { x, y };
                const offset = distance(a, b) - (distance(a, c) + distance(b, c));
                return Math.abs(offset) < 1 ? "inside" : null;
            case "rectangle":
                const minX = Math.min(x1, x2);
                const maxX = Math.max(x1, x2);
                const minY = Math.min(y1, y2);
                const maxY = Math.max(y1, y2);
                if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
                    const topLeft = distance({ x: minX, y: minY }, { x, y });
                    const topRight = distance({ x: maxX, y: minY }, { x, y });
                    const bottomLeft = distance({ x: minX, y: maxY }, { x, y });
                    const bottomRight = distance({ x: maxX, y: maxY }, { x, y });
                    const minDistance = Math.min(topLeft, topRight, bottomLeft, bottomRight);
                    switch (minDistance) {
                        case topLeft:
                            return "tl";
                        case topRight:
                            return "tr";
                        case bottomLeft:
                            return "bl";
                        case bottomRight:
                            return "br";
                        default:
                            return "inside";
                    }
                }
                return null;
            case "pencil":
                const betweenPoint = element.points.find(point => {
                    const offset = distance(point, { x, y }) - (distance(point, element.points[0]) + distance(point, element.points[element.points.length - 1]));
                    return Math.abs(offset) < 1;
                });
                return betweenPoint ? "inside" : null;
            case "text":
                return x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null;
            default:
                throw new Error(`Type not recognised: ${type}`);
        }
    };

    const adjustElementCoordinates = element => {
        const { type, x1, x2, y1, y2 } = element;
        if (type === "rectangle") {
            const minX = Math.min(x1, x2);
            const maxX = Math.max(x1, x2);
            const minY = Math.min(y1, y2);
            const maxY = Math.max(y1, y2);
            return { x1: minX, y1: minY, x2: maxX, y2: maxY };
        } else {
            if (x1 < x2 || (x1 === x2 && y1 < y2)) {
                return { x1, y1, x2, y2 };
            } else {
                return { x1: x2, y1: y2, x2: x1, y2: y1 };
            }
        }
    };

    const distance = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

    return (
        <div>
            <div className="toolbar">
                <input type="radio" id="selection" checked={tool === "selection"} onChange={() => setTool("selection")} />
                <label htmlFor="selection">Selection</label>
                <input type="radio" id="line" checked={tool === "line"} onChange={() => setTool("line")} />
                <label htmlFor="line">Line</label>
                <input type="radio" id="rectangle" checked={tool === "rectangle"} onChange={() => setTool("rectangle")} />
                <label htmlFor="rectangle">Rectangle</label>
                <input type="radio" id="pencil" checked={tool === "pencil"} onChange={() => setTool("pencil")} />
                <label htmlFor="pencil">Pencil</label>
                <input type="radio" id="text" checked={tool === "text"} onChange={() => setTool("text")} />
                <label htmlFor="text">Text</label>
            </div>
            <div className="actions">
                <button onClick={undo}>Undo</button>
                <button onClick={redo}>Redo</button>
                {
                    isConnected ? <button onClick={saveDrawingAsPNG}>Mint Your Art</button> : <w3m-button />
                }
            </div>
            {action === "writing" ? (
                <textarea ref={textAreaRef} onBlur={handleBlur} className="text-area" />
            ) : null}
            <div style={canvasStyle}>
                <canvas
                    id="canvas"
                    width={window.innerWidth * 0.7}
                    height={window.innerHeight * 0.7}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    Canvas
                </canvas>
            </div>
        </div>
    );
};

export default Draw;
