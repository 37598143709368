import React, { useState } from "react";
import Draw from "./Draw";
import MainComponent from "./MainModel";
import Modal from "./UploadandMint";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";


const ParentComponent = () => {
    const [showMintModal, setShowMintModal] = useState(false);
    const [drawingImage, setDrawingImage] = useState("");
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const handleSaveDrawing = async (imageData) => {
        setDrawingImage(imageData);
        setShowMintModal(true);
    };

    const closeModal = () => {
        setShowMintModal(false);
    };

    return (
        <div>
            <Draw handleSaveDrawing={handleSaveDrawing} />
            {
                isConnected ? <>
                 {showMintModal && (
                <Modal drawingImage={drawingImage} isOpen={showMintModal} onClose={closeModal} />
            )}
                </> : <>
                </>
            }
        </div>
    );
};

export default ParentComponent;
