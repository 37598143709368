import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { createRoot } from "react-dom/client";
import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Like from "./components/Like";
import Navbar from "./components/Navbar";
import Release from "./components/Release";
import ScrollToTop from "./components/ScrollToTop";
import Signup from "./components/Signup";
import SuperRare from "./components/SuperRare";
import scrollreveal from "scrollreveal";
import NFTList from './components/FatchAll';
import "./sass/index.scss";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Terms from './components/Terms';
import Policy from './components/Policy';
import Disclaimer from './components/Disclaimer';
import Error from "./components/Error"
import Copyright from './components/Copyright';
import Draw from './components/Draw';
import ParentComponent from './components/ParentComponent';
import Free from './components/Free';







// 1. Get projectId
const projectId = 'bcdc160f39a75617f9ba55c4637848be'

// 2. Set chains
const Polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com/',
  rpcUrl: 'https://polygon-rpc.com/'
}

// const Spolia = {
//   chainId: 11155111,
//   name: 'Sepolia test network',
//   currency: 'SepoliaETH',
//   explorerUrl: 'https://sepolia.etherscan.io',
//   rpcUrl: 'https://sepolia.infura.io/v3/'
// }
// const Amoy = {
//   chainId: 80002,
//   name: 'Amoy',
//   currency: 'MATIC',
//   explorerUrl: 'https://www.oklink.com/amoy',
//   rpcUrl: 'https://rpc-amoy.polygon.technology'
// }

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [Polygon],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {

  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    theme === "dark" ? setTheme("dark") : setTheme("dark");
  };
  useEffect(() => {
    const registerAnimations = () => {
      const sr = scrollreveal({
        origin: "bottom",
        distance: "80px",
        duration: 2000,
        reset: false,
      });
      sr.reveal(
        `
        nav,
        .home,
        .free,
        .clients,
        .super-rare,
        .releases,
        .like,
        .signup,
        footer
    `,
        {
          interval: 500,
        }
      );
    };
    registerAnimations();

    window.setTimeout(() => {
      const home = document.getElementsByClassName("home");
      if (home.length > 0) { // Check if the element exists
        home[0].style.transform = "none";
      }
      const nav = document.getElementsByTagName("nav");
      if (nav.length > 0) { // Check if the element exists
        nav[0].style.transform = "none";
      }
    }, 1500);

  }, []);
  // window.setTimeout(() => {
  //   const home = document.getElementsByClassName("home");
  //   home[0].style.transform = "none";
  //   const nav = document.getElementsByTagName("nav");
  //   nav[0].style.transform = "none";
  // }, 1500);


  const router = createBrowserRouter([
    {
      path: "/",
      element: <div data-theme={theme} className="app-container">
        <ScrollToTop />
        <Navbar changeTheme={changeTheme} currentTheme={theme} />
        <Home />

        <Release />
        <Free />
        {/* <Clients /> */}
        <SuperRare />
     
        <Like />
        <Signup />

        <Footer />
      </div>
    },
    {
      path: "/explore",
      element: <div data-theme={theme} className="app-container"><NFTList /></div>
    },
    {
      path: "/terms",
      element: <Terms />
    },
    {
      path: "/privacy",
      element: <Policy />
    },
    {
      path: "/legal-disclaimer",
      element: <Disclaimer />
    },
    {
      path: "/Copyright",
      element: <Copyright />
    },
    {
      path: '/draw',
      element: <ParentComponent />
    },
    {
      path: "*",
      element: <Error />
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
