import React, { useEffect, useState } from "react";
import { Abi, contractAddress } from "../constant/Contract";
import { Link } from "react-router-dom";
import { Contract } from "ethers";
import { BrowserProvider, parseEther } from "ethers";
import "./Nft.css";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

const NFTList = () => {
  const [nftData, setNftData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const fetchNFTs = async () => {
    if (!isConnected) throw Error("User disconnected");
    // if (!chainId == 137) return alert("Please Select Polygon Network");

    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(contractAddress, Abi, signer);

    // Fetch all NFT metadata URLs

    if (chainId == 137) {
      const response = await contract.getAllTokenURIs(); // Fetch data from contract
      console.log(response);

      const nftDataArray = [];
      for (const uri of response) {
        try {
          const response = await fetch(uri); // Fetch data from each URI
          const metadata = await response.json(); // Parse JSON response
          console.log("meta", metadata);
          const formattedData = {
            // Extract and format data from metadata
            name: metadata.name, // Replace with actual property names
            description: metadata.description,
            animationUrl: metadata.animation_url,
          };

          console.log(formattedData);
          nftDataArray.push(formattedData);
        } catch (error) {
          console.error(error); // Handle the error
        }
      }

      setNftData(nftDataArray);
    } else {
      alert("Please Select Polygon Network");
    }
  };

  useEffect(() => {
    if (isConnected) {
      fetchNFTs();
    }
  }, [isConnected, chainId]);

  // Function to handle search
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter nftData based on searchQuery
  const filteredNFTs = nftData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className=""
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        <h2
          style={{
            textAlign: "center",
            color: "white",
          }}
        >
          Cryptvideos
        </h2>

        {isConnected ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "wheat",
                  padding: "10px",
                }}
              >
                Control over your content, how you create it, but also how other
                people consume it.
              </p>

              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  padding: "10px",
                  margin: "10px",
                  textAlign: "center",
                  color: "whitesmoke",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid ",
                }}
              >
                Back To Home{" "}
              </Link>
            </div>
            <div className="search__container">
              <input
                type="text"
                placeholder="Search by name"
                className="search__input"
                value={searchQuery}
                onChange={handleSearch}
                style={{
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  outline: "none",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              className=" "
            >
              {filteredNFTs.length > 0 ? ( // Check if any NFTs exist before rendering
                filteredNFTs.map((item, id) => (
                  <div
                    className="nft"
                    key={id + 1}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "20px",
                      border: "1px solid #fff",
                      padding: "10px",
                      margin: "10px",
                    }}
                  >
                    <video
                      controls
                      className="tokenImage"
                      src={item.animationUrl}
                      alt={item.name}
                       
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                      }}
                    >
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                      <a
                        href={`https://opensea.io/assets/matic/0x57e3d286f1858b10f0daa2b4e9fe14ccc6d201cf/${
                          id + 1
                        }`}
                        target="_blank"
                        className="button-54"
                        role="button"
                      >
                        Available on OpenSea
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="bg" style={{
                    textAlign:"center",
                    color:"wheat",
                    padding:"20px"
                  }}>Loading... Please Wait </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <p
                style={{
                  padding: "10px",
                }}
              >
                Please Connect Wallet First
              </p>
              <w3m-button />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NFTList;
