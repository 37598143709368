import React from "react";
import signup from "../assets/home.png";
import MainComponent from "./MainModel";
export default function Signup() {
  return (
    <div className="signup" >
      <div className="container">
        <div className="content">
          <p className="sub-title">CryptoVideos</p>
          <h1 className="title">An NFT like no other</h1>
          <p className="description">
            Embrace the future of content creation with CryptVideos, where the
            boundaries are limitless, and the opportunities boundless.
          </p>
         {/* <MainComponent /> */}
         {/* <a href="button">Upload Now</a> */}
        </div>
        <div className="image-container">
          <div className="image">
            <img src={signup} alt="home image" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
