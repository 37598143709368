import React from "react";
import logo from "../assets/toplogo.png";
import "./main.css"
import { Link } from "react-router-dom";


function Copyright() {
  return (
    <div>
      <div style={{
        marginTop:"40px"
      }}>
        <Link to="/" className="button-54" role="button">
          Home{" "}
        </Link>
      </div>

      <div>
        <div className="logo">
          <img src={logo} alt="logo" className="my-img" />
          CRYPTVIDEOS
        </div>
      </div>
      <div class="warning">
        <p>
          Warning- The information on this Web site is protected by the
          Copyright Laws of the United States,17 U.S.C Section 101 et seq.
          Reproduction and distribution of the information on this web site
          without the prior written consent of the owners is strictly prohibited
          and may subject the infringer to civil and criminal penalties.
          CryptVideos,LLC. hereby disclaims any and all liability with regard to
          the accuracy and completeness of the information presented on this web
          site including but not limited to,direct,indirect and consequential
          damages.
        </p>
      </div>
    </div>
  );
}

export default Copyright;
