import React from "react";
import "./main.css";
import logo from "../assets/toplogo.png";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="myfont mybg">
      <div>
        <Link to="/" className="button-54" role="button">
          Home{" "}
        </Link>
      </div>
      <div className="logo">
        <img src={logo} alt="logo" className="my-img" />
        CRYPTVIDEOS
      </div>

      <div className="terms-of-service">
        <h1>Terms of Service</h1>
        <br />
        <p>
          The following terms of service (these "Terms of Service") govern your
          access to and use of 'CRYPTVIDEOS' services, also known as
          'CYPTVIDEOS,' including any material, functionality, and services
          supplied on or via www.cryptvideos.com (the "Site or website").
        </p>
        {/* Additional terms and conditions follow */}
        <br />
        <p>
          Please read the Terms of Service carefully before you start to use the
          Site. By accessing, browsing, registering to use the Site, or Services
          or by clicking to accept or agree to the Terms of Service & our
          privacy policy when this option is made available to you, you
          acknowledge that you have read, understood accept and agree to be
          bound and abide by these Terms of Service and our Privacy Policy,
          incorporated herein by reference. If you do not agree to these Terms
          of Use & Privacy Policy, do not use any portion of the Site, or the
          Services. Failure to use the Site in accordance with these Terms &
          Privacy Policy may subject you to civil and criminal penalties. By
          clicking to accept the provisions of these terms, you agree and
          acknowledge that you are bound by these terms and conditions even if
          English is not your first language, and we will not be held liable to
          you as a result. If you do not agree with all of the provisions of
          this agreement, you cannot use the Services. To remove any doubt, in
          the event of any conflict or discrepancy between these Terms and
          conditions and any other provisions and/or terms and/or otherwise
          between us and you, the provisions and the terms of these Terms of Use
          will prevail. Please feel free to contact us with any questions
          regarding the content of this agreement.
        </p>
        <br />
        {/* <!-- 1. ACCEPTANCE OF TERMS --> */}
        <section id="acceptance-of-terms">
          <h2>ACCEPTANCE OF TERMS</h2>
          <br />
          <p>
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity ("you" or
            "your" or "user") and 'CRYPTVIDEOS' also known as 'CYPTVIDEOS'
            ("CRYPTVIDEOS", "we", "us" or "our"), concerning your access to and
            use of <a href="www.cryptvideos.com">www.cryptvideos.com</a>. You
            agree that by accessing the Site, you have read, understood, and
            agree to be bound by the terms and conditions and Privacy Policy
            incorporated. IF YOU DO NOT AGREE WITH ALL OF THESE TOU, THEN YOU
            ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            In these Terms, "you" and "your" refer to the individual or entity
            that uses the Site, or Services. "We", "us", or "our" refer to
            CRYPTVIDEOS. In addition, in these Terms, unless the context
            requires otherwise, words in one gender include all genders and
            words in the singular include the plural and vice-versa.
          </p>
        </section>
        <br />
        {/* 
  <!-- 2. USAGE/ ELIGIBILITY --> */}
        <section id="usage-eligibility">
          <h2>USAGE/ ELIGIBILITY</h2>
          <br />
          <p>
            You will use this site in a manner consistent with any, and all,
            applicable laws, legislation, rules and regulations. If you violate
            any restrictions in these terms, you agree to indemnify CRYPTVIDEOS
            for any losses, costs or damages, including reasonable legal fees,
            incurred by CRYPTVIDEOS in relation to, or arising out of, such a
            breach.
          </p>
        </section>
        <br />

        {/* <!-- 3. ABOUT CRYPTVIDEOS SERVICES --> */}
        <section id="about-cryptvideos-services">
          <h2>ABOUT CRYPTVIDEOS SERVICES</h2>
          <br />
          <p>
            CRYPTVIDEOS carries on its business activities in Wyoming, United
            States. We have created an NFT video upload website where users will
            utilize our token to have their videos submitted. The user would be
            charged a service fee to use the services. They may be able to make
            money while utilizing the platform. The user agrees and acknowledges
            that earnings from the site are entirely at the discretion of the
            company, and the user undertakes to hold the company harmless if the
            user does not generate any earnings while using the site. You agree
            and acknowledge that use of the services provided is not insured,
            that the site is provided to you 'AS IS'.
          </p>
          <p>
            The Website allows Users to create a profile, upload photos and
            videos onto their profile. The CRYPTVIDEOS service can be accessed
            from devices with Internet Connectivity, such as personal computers,
            tablets and smartphones. We don't sell your personal data to
            advertisers, and we don't share information that directly identifies
            you (such as your name, email address or other contact information)
            with advertisers unless you give us specific permission. Our Privacy
            Policy which can be accessed via{" "}
            <a href="www.cryptvideos.com/privacy">
              www.cryptvideos.com/privacy
            </a>
            , and explains how we collect and use your personal data.
          </p>
        </section>
        <br />
        {/* <!-- 4. YOUR ACCOUNT --> */}
        <section id="your-account">
          <h2>YOUR ACCOUNT</h2>
          <br />
          <p>
            You will be required to create an account on the website before
            using the Services, your username and password will be chosen by
            you. You are responsible for all actions taken under your chosen
            username and password.
          </p>
          <p>
            To become a USER, the user must go through our verification
            procedure to validate the account generated in order to avoid
            duplication. You must provide your Name, date of birth, a valid
            email address, a username, and a password or a valid Twitter,
            Facebook or Google account for easy sign up. It is a condition of
            your use of the Website that all the information you provide on the
            Website is correct, current, and complete.
          </p>
          <p>
            You agree that all information you provide as a User or otherwise,
            including but not limited to information provided through the use of
            any interactive features on the Website, is governed by our Privacy
            Policy, and you proceed on the basis that you are aware of how and
            why we process your personal data, as is set out in Privacy Policy.
          </p>
          <p>
            If you wish to view User Content you will need to provide details of
            a payment card to a Payment Provider. Your payment card information
            is stored by the third-party Payment Provider used on the site.
          </p>
        </section>
        <br />
        {/* <!-- 5. EXCLUSION OF LIABILITY FOR EXTERNAL LINKS --> */}
        <section id="exclusion-of-liability">
          <h2>EXCLUSION OF LIABILITY FOR EXTERNAL LINKS</h2>
          <br />
          <p>
            The Website may provide links to external Internet sites.
            CRYPTVIDEOS hereby declares explicitly that it has no influence on
            the layout or content of linked pages and dissociates itself
            expressly from all contents of all linked pages of third parties.
            CRYPTVIDEOS shall not be liable for the use or content of Internet
            sites that link to this site or which are linked from it. Our
            privacy and cookie notice do not apply to any collection and
            processing of your personal data on or through such external sites.
          </p>
        </section>
        <br />
        <h2>
          USER ELIGIBILITY, SITE CONDUCT, POSTING POLICIES & THIRD-PARTY
          WEBSITES
        </h2>
        <br />
        <p>
          User Eligibility. Only individuals who can form legally binding
          contracts are eligible to use the Site and its services. Thus, only
          Users from 18 and above can use the site. User-Created Submissions
          Guidelines. Your use of the Site is subject to all applicable laws and
          regulations, and you are solely responsible for any posts,
          information, videos, pictures, advertisements, feedback or other
          material that you upload or submit to the Site ("Submissions"). By
          transmitting Submissions to the Site, you agree that you will not
          transmit or upload any Submissions that: are false, misleading or
          inaccurate; victimize, harass, degrade, discriminate against, or
          intimidate an individual or group of individuals on the basis of
          religion, gender, sexual orientation, race, ethnicity, age, or
          disability; infringe on any patent, trademark, trade secret,
          copyright, right of publicity, right to privacy or other proprietary
          right of another; promote or encourage any type of criminal or illegal
          activity, including but not limited to, child pornography or the use
          of illicit drugs; involve: pornography, sexually explicit material or
          sexually oriented services; drugs or narcotics, whether prescription
          or otherwise, or drug paraphernalia; alcohol or tobacco related items
          firearms, weapons, ammunition or any parts and accessories of such
          items stolen or counterfeit items stocks, bonds or securities of any
          kind violate any federal, state or local law, statute or ordinance.
          You are solely responsible for determining the legality of the goods
          or services that you post on the Site and making sure they are in
          compliance with all rules and trading restrictions; contain any form
          of malicious code, files, or programs that are designed or intended to
          disrupt, damage, or limit the functionality of any software, hardware,
          or telecommunications equipment or otherwise causes damage, or allow
          you to obtain unauthorized access to any data or other information of
          any third party; breach the security of, compromise or otherwise allow
          access to secured, protected or inaccessible areas of this Site, or
          attempt to gain access to another network or server; impersonate any
          person or entity, including any of our employees or representatives;
          you know or reasonably should know cannot be distributed legally, or
          are for any illegal or unauthorized purpose. Please note that the list
          above is not exhaustive. CRYPTVIDEOS reserves the right, without
          notice and in its sole discretion, to block your use of or prevent
          your future access to the Site and any of its services upon its
          determination that you have engaged in or are engaging in a forbidden
          activity, whether or not such activity is listed above. No
          Endorsement. CRYPTVIDEOS neither endorses nor assumes any liability
          for any Submissions, products, services, or promotions displayed or
          submitted by you or other users through or on any part of the Site. We
          and our agents reserve the right to remove or refuse to display any
          and all Submissions, in our sole discretion and without prior notice
          to you. We are not responsible for any failure or delay in removing or
          refusing to post any Submissions. Third-Party Sites and Information.
          This Site may redirect or link to other websites on the Internet, or
          may otherwise include references to information, products or services
          made available by unaffiliated third parties. While we make every
          effort to work with trusted, reputable providers, from time to time
          such sites may contain information, material or policies that some may
          find inappropriate or personally objectionable. You understand that we
          are not responsible for the accuracy, completeness, decency or
          legality of material hosted by third party websites, nor are we
          responsible for errors or omissions in any references made on those
          websites. The inclusion of such a link or reference is provided merely
          as a convenience and does not imply endorsement of, or association
          with the Site or party by us, or any warranty of any kind, either
          express or implied.
        </p>
        <br />
        <h2>CRYPTVIDEOS' INTELLECTUAL PROPERTY</h2>
        <br />
        <p>
          Content For purposes of these Terms, "content" is defined as any
          information, communications, published works, photos, NFT videos,
          graphics, music, sounds, or other materials that can be viewed by
          users on our Site and is owned by CRYPTVIDEOS, our Affiliates or our
          licensors. Ownership of Content All content on the Site is subject to
          intellectual property rights, contractual or other protection. The
          intellectual property rights are owned by us or our licensors. No
          content may be copied, distributed, republished, uploaded, posted or
          transmitted in any way except as provided expressly in these Terms or
          with CRYPTVIDEOS's prior express written consent. Any use of the
          content other than as permitted by these Terms, or any other
          unauthorized use of the content may make you liable to CRYPTVIDEOS or
          its licensors for violation of intellectual property rights.
          Trademarks or service marks of CRYPTVIDEOS include, but are not
          limited to, CRYPTVIDEOS and the CRYPTVIDEOS logo. All custom graphics,
          icons, logos and service names are registered or common-law trademarks
          or service marks of CRYPTVIDEOS or our Affiliates. All other
          trademarks or service marks are property of their respective owners.
          Nothing in these Terms grants you any right to use any trademark,
          service mark, logo, and/or the name of CRYPTVIDEOS, our Affiliates or
          our licensors. Site Use CRYPTVIDEOS grants you a limited, revocable,
          nonexclusive license to use the content on the Site solely for your
          own personal purposes only and not for republication, distribution,
          assignment, sublicense, sale, preparation of derivative works, or
          other use. You may not use any content from the Site which is not
          owned by you for commercial use. You agree not to copy the Site or
          content located on the Site; to reverse engineer or break into the
          Site; or to use content, products or services in violation of any law.
          Any use of the Site or the content contained therein other than as
          specifically authorized in the Agreement, without the prior written
          permission of CRYPTVIDEOS, is strictly prohibited and will terminate
          the license granted herein. Unless explicitly stated herein, nothing
          in the Agreement shall be construed as conferring to you, whether by
          implication, estoppel or otherwise, any title or ownership of, or
          exclusive use-rights to, any intellectual property or other right and
          any goodwill associated therewith. CRYPTVIDEOS reserves the right,
          without notice and in its sole discretion, to terminate your license
          to use the Site at any time and to block or prevent your future access
          to, and use of, the Site. No Warranty for Third-Party Infringement
          Neither we nor our Affiliates warrant or represent that your use of
          materials displayed on, or obtained through, this Site will not
          infringe the rights of other users of the Site or of third parties.
        </p>
        <br />
        <h2>YOUR INTELLECTUAL PROPERTY</h2>
        <br />
        <p>
          Your Intellectual Property Rights Subject to our Privacy Policy, any
          Submissions by you will be treated as non-confidential. While you
          retain all rights to the Submissions, you grant CRYPTVIDEOS (including
          our employees and Affiliates) as well as the users of the Site, a
          non-exclusive, paid-up, perpetual, non-exclusive and worldwide license
          to copy, distribute, display, publish, translate, adapt, modify, and
          otherwise use the Submissions on the Site and to effectuate
          CRYPTVIDEOS's services and business without incurring any liability
          for royalties or any other consideration of any kind.
        </p>
        <br />
        <h3>Creator's Content</h3>
        <br />
        <p>
          By creating and publishing User Content on CRYPTVIDEOS, you authorize
          your USERS to access and view (without downloading or copying) your
          User Content on CRYPTVIDEOS, for their own lawful and personal use.
          You also represent, warrant and undertake that for each submission:
          <ul>
            <li>
              you own, have a valid licence to, or otherwise control all rights
              in and to your User Content;
            </li>
            <li>
              to the extent your User Content includes or utilises any
              third-party property, you have secured all rights, licenses,
              written consents and releases that are necessary for the use of
              such third-party property in your User Content;
            </li>
            <li>
              you will not post any content depicting any person under 18-years
              old,
            </li>
            <li>
              you have inspected and are maintaining written documentation
              sufficient to confirm that all subjects of your submission are
              within the usage eligibility requirements.
            </li>
            <li>
              your User Content is non-confidential and will be made available
              to your USERS on the platform.
            </li>
          </ul>
        </p>
        <p>
          You grant CRYPTVIDEOS and Our licensees, successors, and assigns the
          right to use, reproduce, modify, perform, display, distribute, and
          otherwise disclose to third parties any such material.
        </p>
        <p>
          For clarification: The clause exists so that we may use your content
          by adding stickers, text, and watermarks, and to make your content
          available to Users, as well as for other normal operations of our
          website. We will never sell your content to other platforms.
        </p>
        <p>
          You understand and acknowledge that you are responsible for any User
          Content you submit or contribute, and you have full responsibility for
          such content, including its legality, reliability, accuracy, and
          appropriateness.
        </p>
        <p>
          You shall indemnify CRYPTVIDEOS our licensees, successors, and assigns
          against all liabilities, costs, expenses, damages and losses
          (including any direct, indirect or consequential losses, loss of
          profit, loss of reputation and all interest, penalties and legal costs
          (calculated on a full indemnity basis) and all other reasonable
          professional costs and expenses suffered or incurred arising out of or
          in connection with your User Content.
        </p>
        <p>
          We are not responsible or liable to any third party for the content or
          accuracy of any User Content posted by you or any other user of the
          Website.
        </p>
        <p>
          Any questions regarding User Content can be addressed by emailing
          [Insert Email Address].
        </p>
        <br />
        <h3>Copyright Notice</h3>
        <br />
        <p>
          We respect the intellectual property rights of others, and we ask you
          to do the same. In instances where we are notified of alleged
          infringing CRYPTVIDEOS content or Submissions, a decision may be made
          to remove or disable access to such content or Submissions, in
          compliance with the safe harbour provisions of the Digital Millennium
          Copyright Act, 17 U.S.C, 512 (c).
        </p>
        <p>
          If you believe that you or someone else's copyright has been infringed
          by content or Submissions provided on this Site, you (or the owner or
          rights holder, collectively, "Rights Holder") should send notification
          to us at [Insert Email Address], immediately. Prior to sending us
          notice, the Rights Holder may wish to consult a lawyer to determine
          their rights and legal obligations under the DMCA and any other
          applicable laws. Nothing here or anywhere on this Site is intended as
          a substitute for qualified legal advice. To file a Notice of
          Infringing Material, we ask that the Rights Holder provide the
          following information:
          <ul>
            <li>
              Reasonably sufficient details about the nature of the copyrighted
              work in question, or, in the case of multiple alleged
              infringements, a representative list of such works. This should
              include, title(s), author(s), any U.S. Copyright Registration
              number(s), URL(s) etc.;
            </li>
            <li>
              Reasonably sufficient details to enable us to identify and locate
              the material that is allegedly infringing the Rights Holder's
              work(s) (for example, file name or URL of the page(s) that
              contain(s) the material);
            </li>
            <li>
              The Rights Holder's contact information so that we can contact
              them (including for example, the Rights Holder's address,
              telephone number, and email address);
            </li>
            <li>
              A statement that the Rights Holder has a good faith belief that
              the use of the material identified above in Part (ii) is not
              authorized by the copyright owner, its agent, or the law;
            </li>
            <li>
              A statement, under penalty of perjury, that the information in the
              notification is accurate and that the Rights Holder is authorized
              to act on behalf of the copyright owner; and
            </li>
            <li>The Rights Holder's signature or electronic signature.</li>
          </ul>
        </p>
        <br />
        <h3>YOUR REPRESENTATIONS</h3>
        <br />
        <p>
          By using the Site, you represent and warrant that:
          <ol>
            <li>
              all registration information you submit will be true, accurate,
              current, and complete;
            </li>
            <li>
              you will maintain the accuracy of such information and promptly
              update such registration information as necessary;
            </li>
            <li>
              you have the legal capacity and you agree to comply with these
              Terms of Use;
            </li>
            <li>you are not under the age of 18;</li>
            <li>
              you are not a minor in the jurisdiction of which you reside, or if
              a minor, you have received parental permission to use the Site;
            </li>
            <li>
              you will not access the Site through automated or non-human means,
              whether through a bot, script, or otherwise;
            </li>
            <li>
              you will not use the Site for any illegal or unauthorized purpose;
              and
            </li>
            <li>
              your use of the Site will not violate any applicable law or
              regulation.
            </li>
          </ol>
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        <b />
        <ol>
          <li>
            Interfere with, disrupt, or create an undue burden of the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the Site.
          </li>
          <li>
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Copy or adapt the Site's software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any parties' functions, operation, or maintenance of
            the Site.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats ("gifs"), 1x1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as "spyware" or
            "passive collection mechanisms" or pcms").
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
          <li>Use content without proper attribution.</li>
          <li>
            Use content in a fashion that does not comply with the content's
            specific licensing.
          </li>
        </ol>

        <br />

        <h3>DISCLAIMERS</h3>
        <br />
        <p>
          Your access to and use of the services and content provided on the
          website are at YOUR OWN RISK. You understand and agree that the
          Services are provided to you on an "AS IS" and "AS AVAILABLE" basis.
          Without limiting the foregoing, to the maximum extent permitted under
          applicable law, (CRYPTVIDEOS ENTITIES are CRYPTVIDEOS founders,
          officers, directors, employees, agents, representatives, and partners)
          DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, and OR
          NON-INFRINGEMENT.
        </p>
        <p>
          CRYPTVIDEOS make no warranty and disclaim all responsibility and
          liability for: (i) the completeness, accuracy, availability,
          timeliness, security or reliability of the Services or any Content;
          (ii) any harm to your computer system, loss of data, or other harm
          that results from your access to or use of the Services or any
          Content; (iii) the deletion of, or the failure to store or to
          transmit, any Content and other communications maintained by the
          Services; and (iv) whether the Services will meet your requirements or
          be available on an uninterrupted, secure, or error-free basis. No
          advice or information, whether oral or written, obtained from
          CRYPTVIDEOS or through the Services, will create any warranty not
          expressly made herein.
        </p>
        <p>
          The Services may contain links to third-party websites or resources.
          You acknowledge and agree that the CRYPTVIDEOS is not responsible or
          liable for: (i) the availability or accuracy of such websites or
          resources; or (ii) the content, products, or services on or available
          from such websites or resources. Links to such websites or resources
          do not imply any endorsement by CRYPTVIDEOS of such websites or
          resources or the content, products, or services available from such
          websites or resources. You acknowledge sole responsibility for and
          assume all risk arising from your use of any such websites or
          resources.
        </p>
        <p>
          We reserve the right to change any and all Content and to modify,
          suspend or stop providing access to the Sites (or any features or
          functionality of the Sites) and the Products at any time without
          notice and without obligation or liability to you. Reference to any
          products, services, processes or other information by trade name,
          trademark, manufacturer, supplier, and vendor or otherwise does not
          constitute or imply endorsement, sponsorship or recommendation
          thereof, or any affiliation therewith, by us. Some jurisdictions do
          not allow the disclaimer of implied terms in contracts with consumers,
          so some or all of the disclaimers in this Section may not apply to
          you.
        </p>
        <br />
        <h3>CHANGES</h3>
        <br />
        <p>
          If CRYPTVIDEOS decides to change these general terms and conditions,
          we will post the changed terms and conditions on the Website. You are
          advised to regularly check whether they have changed. Existing
          contracts will not be affected by such changes.
        </p>
        <br />
        <h3>GOVERNING LAW AND JURISDICTION</h3>
        <br />
        <p>
          This general terms and conditions in relation to the use of the
          website is hereby governed by, and constructed and enforced in
          accordance with the laws of Wyoming. The competent courts in Wyoming
          shall have the exclusive jurisdiction to resolve any dispute between
          you and CRYPTVIDEOS.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
