import React from "react";
import logo from "../assets/toplogo.png";
import "./main.css"
import { Link } from "react-router-dom";

function Policy() {
  return (
    <div className="mybg myfont">
       <div>
        <Link to="/" className="button-54" role="button">
          Home{" "}
        </Link>
      </div>
        
      <div>
        <div className="logo">
          <img src={logo} alt="logo" className="my-img" />
          CRYPTVIDEOS
        </div>
      </div>
      <div className="terms-of-service">
        <h1>PRIVACY POLICY</h1>
        <br/>
        <p>
          <strong>PLEASE READ THE FOLLOWING CAREFULLY</strong>
        </p>
        <p>
          <strong>
            THIS STATEMENT PROVIDES GENERAL INFORMATION ABOUT THE PRIVACY
            STATEMENT OF THIS WEBSITE. IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE
            BE SURE TO READ THIS PRIVACY STATEMENT WITH YOUR PARENTS OR GUARDIAN
            AND ASK THEM QUESTIONS ABOUT WHAT YOU DO NOT UNDERSTAND.
          </strong>
        </p>
        <p>
          <strong>
            YOUR USE OF THIS SERVICE CONSTITUTES ACCEPTANCE BY YOU OF THIS
            PRIVACY STATEMENT.
          </strong>
        </p>
        <br/>
        <p>
          <strong>CRYPTVIDEOS</strong> also known as <strong>CYPTVIDEOS</strong>{" "}
          ("CRYPTVIDEOS", "we", "our" and "us".) has created this privacy
          statement ("Statement") in order to demonstrate its firm commitment to
          the privacy of the details that you provide to us when using{" "}
          <a href="www.cryptvideos.com">www.cryptvideos.com</a> ("collectively
          "the website"), as the data controller for the purposes of the
          relevant United States Data Protection Law - California Consumer
          Protection Act (CCPA), California Online Privacy Protection Act,
          (CALOPPA) and the EU General Data Protection Regulation (GDPR) 2016.
        </p>
        <p>
          At CRYPTVIDEOS, we are committed to maintaining the trust and
          confidence of all visitors to our website. In particular, we want you
          to know that the website is not in the business of selling, renting or
          trading email lists with other companies and businesses for marketing
          purposes.
        </p>
        <p>
          We believe your business is no one else's. Your Privacy is important
          to you and to us. So, we'll protect the information you share with us.
          To protect your privacy, CRYPTVIDEOS follows different principles in
          accordance with worldwide practices for customer privacy and data
          protection.
        </p>
        <ul>
          <li>
            We won't sell or give away your name, mail address, phone number,
            email address or any other information to anyone.
          </li>
          <li>
            We'll use state-of-the-art security measures to protect your
            information from unauthorized users.
          </li>
        </ul>
        <p>
          Therefore, to provide you with our services we need (and sometimes are
          obliged by the law) to collect your personal data. This Privacy Policy
          (the "Policy") informs Users (a "User", or "You") of our policies
          regarding the processing of Personal Information we receive from Users
          of the site.
        </p>
        <p>
          In this Privacy Policy, we've provided detailed information on when
          and why we collect personal information, how we use it, the limited
          conditions under which we may disclose it to others, and how we keep
          it secure.
        </p>
        <p>
          We take your privacy seriously and take measures to provide all
          visitors and users of the website with a safe and secure environment.
        </p>
        <p>
          The Personal Information on the site is collected, controlled, and
          processed by the data controller; collected on behalf of;
        </p>
        <strong>CRYPTVIDEOS</strong>
        <br />
        
        <h1>Privacy Policy</h1>
        <h2>Information We Collect and Obtain</h2>
        <p>
          We obtain personal information about you from a variety of sources.
          This includes personal information you provide to us directly,
          information we obtain from other sources, and information we gather
          through automated means.
        </p>
        <ul>
          <li>
            <strong>Contact information:</strong> (such as name, postal address,
            email address, and telephone and/or mobile numbers)
          </li>
          <li>
            <strong>Payment Information:</strong> when you want to complete a
            transaction via the site
          </li>
          <li>
            <strong>
              Reviews, comments, and/or surveys on our media blog;
            </strong>
          </li>
          <li>
            <strong>Other information:</strong> you may provide to us when you
            fill a form, such as through our "Contact Us" feature.
          </li>
        </ul>
        <p>
          We may obtain personal information about you in connection with the
          Services from publicly and commercially available sources and from our
          affiliates and/or business partners (such as advertising networks or
          social networking services).
        </p>
        <ul>
          <li>
            <strong>Demographic data:</strong> (such as gender, age range,
            educational level, household income range, number of children in
            household, ethnicity to the extent permitted)
          </li>
          <li>
            <strong>Occupational data:</strong> (such as profession, position,
            title, industry; and business address)
          </li>
        </ul>
        <p>
          We may gather by automated means (such as cookies, web beacons, web
          server logs, JavaScript and other similar technologies) certain
          information through our Services:
        </p>
        <ul>
          <li>Your Internet Protocol (IP) address;</li>
          <li>
            Device information, including unique identifiers and connection
            information, including mobile device advertising IDs (e.g., Apple's
            IDFA or Google's AAID) and the means of internet connection (e.g.,
            WiFi connection, ISP) that can identify the physical location of
            such devices, in accordance with applicable law;
          </li>
          <li>
            Your device type and settings, software used, browser type and
            operating system;
          </li>
          <li>
            Websites or other services you visited before and after visiting the
            Services (referring URL);
          </li>
          <li>
            Web pages and advertisements, you view and links you click on
            within, and what search queries you may have run on, the Services
            (clickstream);
          </li>
          <li>
            Viewing behavior, including the content you view, how long you view
            content, the quality of the service you receive, and advertisements
            you have been shown or interacted with;
          </li>
          <li>Dates and times, you access or use the Services;</li>
          <li>
            Location information, including the city, state and zip code
            associated with your IP Address, information derived through WiFi
            triangulation, and precise location information from GPS-based
            functionality on your mobile apps, with your consent;
          </li>
          <li>
            Your phone number and mobile carrier details in connection with our
            mobile app.
          </li>
        </ul>
        <h2>Cookies and Similar Technologies</h2>
        <p>
          Cookies are small files that we or others send to and store on or with
          your computer so that your computer, browser, mobile app or other
          application can be recognized as unique the next time you access,
          visit, use or otherwise take advantage of the Services or other media.
        </p>
        <p>
          You are always free to decline any cookies we use by adjusting the
          settings of your browser, as your browser may permit; however, some
          products, services or features might not be available or operate
          properly if cookies are not enabled.
        </p>
        <p>
          In addition, we, our service providers and others sometimes use
          data-gathering mechanisms on the Services, including without
          limitation "web beacons", "clear GIFs", "pixels" and/or "tags". These
          perform statistical and administrative functions, such as measuring
          site and page traffic, verifying advertising paths, better
          understanding user interests and activity, gathering related
          information (such as information relating to a particular browser,
          device or IP address) and positioning images, and typically do so
          without detracting from your online experience.
        </p>
        <h2>How We Use the Information We Obtain</h2>
        <p>
          We, or service providers acting on our behalf, may use the information
          collected from and about you to:
        </p>
        <ul>
          <li>
            Provide our services, including authorizing a purchase, or
            completing a transaction that you requested;
          </li>
          <li>
            Send promotional materials as well as running Social Media Ads,
            alerts regarding available offers and other communications,
            including text/SMS messages if you provided your mobile number and
            opted in to receive such messages;
          </li>
          <li>
            Communicate about, and administer participation in, special events,
            promotions, programs, offers, surveys, contests and market research;
          </li>
          <li>
            Respond to inquiries from you and other third-parties, including
            inquiries from law enforcement agencies;
          </li>
          <li>
            Anonymize or de-identify personal information to provide third
            parties with aggregated data reports showing anonymized information
            and other non-personal information;
          </li>
          <li>Provide technical support;</li>
          <li>Generate suggestions about the type of content you may enjoy;</li>
          <li>
            Supplement your personal information collected directly from you
            and/or from automated means with additional information from
            publicly and commercially available sources, and/or information from
            our affiliates and our business partners;
          </li>
          <li>
            Associate your browser and/or device with other browsers or devices
            you use for the purpose of providing relevant and easier access to
            content, advertising across browsers and devices, and other
            operational/business purposes;
          </li>
          <li>
            Operate, evaluate and improve our business (including developing,
            enhancing, analyzing and improving our Services; managing our
            communications; reviewing and processing employment applications,
            performing data and statistical analytics; and performing
            accounting, auditing and other internal functions);
          </li>
          <li>
            Protect against, identify and prevent fraud and other unlawful
            activity, claims and other liabilities;
          </li>
          <li>
            Comply with and enforce applicable legal requirements, relevant
            industry standards, contractual obligations and our terms of service
            and other policies; and
          </li>
          <li>
            In other ways for which we provide specific notice at the time of
            collection.
          </li>
        </ul>
        <h2>Disclosure of Your Personal Information</h2>
        <p>
          We may disclose your personal information to selected third parties,
          including:
        </p>
        <ul>
          <li>
            Third party service providers who perform services on our behalf,
            such as providers of IT and email distribution services, who may use
            your personal information for the purposes mentioned above;
          </li>
          <li>Advisors such as accountants, lawyers, and consultants;</li>
          <li>
            In the event that we sell or buy any business or assets, the
            prospective seller or buyer of such business or assets (and their
            respective advisers), who may use the personal information in
            connection with the sale or purchase;
          </li>
          <li>
            If CRYPTVIDEOS or substantially all of its assets are acquired by a
            third party, to the relevant third party (and its advisers) who may
            use the data in connection with the acquisition;
          </li>
          <li>
            Analytics providers that assist us in the improvement and
            optimization of our Websites;
          </li>
          <li>
            Law enforcement agencies or other third parties for the purposes
            described below.
          </li>
        </ul>
        <p>
          We may also disclose your personal information to third parties if we
          are under a duty to disclose or share your personal information in
          order to comply with any legal obligation, or in order to enforce or
          apply our Website Terms of Use, our Terms and Conditions and other
          agreements, or to protect the rights, property, or safety of
          CRYPTVIDEOS, our customers, or others. This includes exchanging
          information with other companies and organizations for the purposes of
          fraud detection and protection and credit risk reduction.
        </p>
        <br />
        <section>
          <h2>How We Hold and Protect Your Personal Information</h2>
          <p>
            We take all steps reasonably necessary to ensure that your personal
            information is treated securely and in accordance with this privacy
            policy.
          </p>
          <p>
            All information you provide to us is stored on our secure servers.
            Any payment transactions will be carried out by third parties over
            encrypted connections using SSL technology. Where we have given you
            (or where you have chosen) a password or API key which enables you
            to access certain parts of our site, or you have invited team
            members to access parts of our site or apps, you are responsible for
            keeping this password or API key confidential. We ask you not to
            share a password or API key with anyone.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal information, we cannot guarantee the security of your data
            transmitted to our site and any transmission is at your own risk.
            Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorized
            access.
          </p>
        </section>
        <section>
          <h2>Our Retention of Your Personal Information</h2>
          <p>
            The periods for which we keep your information depend on why your
            information was collected and what we use it for. We will not keep
            your personal information for longer than necessary other than for
            our business purposes or for legal requirements.
          </p>
        </section>
        <section>
          <h2>Your Rights to Your Data</h2>
          <p>
            All Your Personal Information we collect will always belong to you.
            However, we are a collector and a processor of Your Personal
            Information. That implies on us obligations to respect your rights
            to Personal Information and facilitate the exercise of your rights
            thereto. In order to use any of your rights at any time please
            contact us and we will facilitate the exercise of your rights free
            of charge. We will inform you on the actions taken by us under your
            request as soon as practically possible, but in any case, not later
            than in 30 (thirty) calendar days.
          </p>
          <ul>
            <li>
              <strong>Right to access:</strong> You may obtain from us the
              confirmation as to whether or not personal data concerning you is
              being processed and get an access to such personal data. You are
              entitled to view, amend, or delete the personal information that
              we hold. Email your request to our data protection office at
              [Insert email address] and we will work with you to remove any of
              your personal data we may have.
            </li>
            <li>
              <strong>Right to rectify:</strong> Your inaccurate Personal
              Information and to have incomplete personal data completed,
              including by means of providing a supplementary statement.
            </li>
            <li>
              <strong>Right to erase:</strong> Your Personal Information. Please
              note that a request to erase your Personal Information will also
              terminate your account on the Site. We will automatically and
              without undue delay erase your Personal Information when it is no
              longer necessary in relation to the purposes for which it was
              collected or otherwise processed;
            </li>
            <li>
              <strong>Right to restrict:</strong> Processing of your Personal
              Information;
            </li>
            <li>
              <strong>Right to data portability:</strong> You may obtain from us
              the personal data concerning you and which you have provided to us
              and transmit it to another Personal Information Controller;
            </li>
            <li>
              <strong>Right to object:</strong> To processing of Your Personal
              Information,
            </li>
            <li>
              <strong>Right to withdraw:</strong> Consent to the usage of your
              Personal Information at any time
            </li>
            <li>
              <strong>Right to lodge a complaint:</strong> We take privacy
              concerns seriously. If you believe that we have not complied with
              this Privacy Policy with respect to your Personal Information, you
              may contact our respective Data Protection Office. We will
              investigate your complaint promptly and will reply you within 30
              (thirty) calendar days.
            </li>
          </ul>
        </section>
        <section>
          <h2>Analytics</h2>
          <p>
            When someone visits the website, we use a third-party service,
            Google Analytics, to collect standard internet log information and
            details of visitor behavior patterns. We do this to track things
            such as the number of visitors to the various parts of the site and
            interactions with the site. This information is processed in a way
            which does not identify anyone. We do not make and do not allow
            Google to make, any attempt to find out the identities of visitors
            to our website.
          </p>
        </section>
        <section>
          <h2>Securing Privacy</h2>
          <p>
            To transfer data between our websites, our applications and
            backends, communication is encrypted using the SSL (Secure Socket
            Layer) encryption. We protect the systems and processing by a series
            of technical and organizational measures. These include data
            encryption, pseudonymization and anonymization, logical and physical
            access restriction and control, firewalls and recovery systems, and
            integrity testing. Our employees are regularly trained in the
            sensitive handling of personal data and are obliged to observe data
            secrecy in accordance with legal requirements.
          </p>
        </section>
        <section>
          <h2>Minors</h2>
          <p>
            We do not knowingly gather or otherwise process personal data of
            minors under the age of 18. If we notice that one of our
            users/visitors is a minor we'll immediately take steps to remove
            their information. If you believe we have processed or still hold
            information on minors, please send us an email at [Insert email
            address] and we'll remove it A.S.A.P.
          </p>
        </section>
        <section>
          <h2>Changes in the Privacy Statement</h2>
          <p>
            The effective date at the top of this page indicates when this
            Privacy Statement was last revised. We will notify you before any
            material change takes effect so that you have time to review the
            changes. Any change is effective when we post the revised Privacy
            Statement. Your use of the Services following these changes means
            that you accept the revised Privacy Statement.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Policy;
