import React from "react";
import { BsArrowRight } from "react-icons/bs";
import release1 from "../assets/release1.png";
import release2 from "../assets/release2.png";
import Card from "./Card";

export default function Release() {
  return (
    <div className="releases" id="about">
      <div className="release orange">
        <div className="content">
          <h2 className="title">About</h2>
          <p className="description">
           Control over your content, how you create it, but also how other people consume it. 
          </p>
          <p className="description">
          Trade or sell their own NFTs and invest in any NFT on the platform, which can eventually be resold for a higher amount.
          </p>
          <p className="description">Create Your Own Content Identity</p>
          <a href="https://www.amazon.com/You-Them-NFTs-Complete-Non-Fungible/dp/B09BGF8XMD" target="_blank" className="link">
          Learn about NFTs
          </a>
        </div>
        <div className="image">
          <img src={release1} alt="release" />
          <div className="ellipse pink"></div>
        </div>
      </div>
      {/* <div className="release green">
        <div className="card-container">
          <Card
            image={release2}
            series="Gloop Series"
            title="Purple Man"
            price={3.95}
            tag="1094"
            time={2}
          />
          <div className="ellipse orange"></div>
        </div>
        <div className="content">
          <h2 className="title">Initial Release 4/11</h2>
          <p className="description">
            We have released four limited edition NFTs early which can be bid on
            via <a href="#">OpenSea</a>
          </p>
          <p className="description">
            There will be the only four of these NFTs we ever make, so be sure
            not to miss out!
          </p>
          <p className="description">50% of proceeds go to charity.</p>
          <a href="#" className="link">
            Check them out <BsArrowRight />
          </a>
        </div>
      </div> */}
    </div>
  );
}
