import React from "react";
import logo from "../assets/toplogo.png";
import "./main.css"
import { Link } from "react-router-dom";

function Disclaimer() {
  return (
    <div className="">
   
      <div
        className="legal-disclaimer mybg myfont"
        style={{
          padding: "10px",
        }}
      >
        <div style={{
          margin:"50px"
        }}>

               
      <div>
        <div className="logo">
          <img src={logo} alt="logo" className="my-img" />
          CRYPTVIDEOS
        </div>
      </div>
        <div>
        <Link to="/" className="button-54" role="button">
          Home{" "}
        </Link>
      </div> 
        </div>
        <br/>
        <h2>PROFESSIONAL DISCLAIMER</h2>
        <br/>
        <p>
          The use of the website and the services on the website cannot and does
          not contain any legal, financial or any other professional advice. All
          of the information provided on our Site is for general informational
          purposes ONLY. You should NOT take any information on our Site as a
          substitute for any professional advice. Before taking action based on
          any such information, we encourage you to consult with the appropriate
          professionals.
        </p>
        <p>
          <strong>
            YOUR ACCESS TO, USE OF, AND RELIANCE ON ANY INFORMATION PROVIDED ON
            OUR SITE IS SOLELY AT YOUR OWN RISK.
          </strong>
        </p>
        <br/>
        <h2>LIMITATION OF LIABILITY</h2>
        <br/>
        <p>
          You agree and recognize that the CRYPTVIDEOS shall not be held liable
          for the individuals you encounter through the service and that it is
          always advisable to use caution when using the internet. Additionally,
          users have the right to report any inappropriate or unlawful behavior
          on your part, at which point you will be immediately deleted from the
          website and database and reported to the appropriate authorities.
        </p>
        <br/>
        <h2>DISCLAIMER FOR EXTERNAL LINKS</h2>
        <br/>
        <p>
          From time to time, our Site may contain links to other external
          websites and third parties. Moreover, we may have ads and banners on
          our Website that contain external links in them to third-party
          websites, products, and companies. We do not check, control, validate,
          monitor, or investigate such links for accuracy, adequacy, validity,
          reliability, availability, or completeness. We do not warrant,
          endorse, guarantee, or assume responsibility for any information or
          product offered by third-party websites. If you choose to click on
          links and be taken to an external website belonging to a third party,
          then you and only you are responsible and liable for your actions
          should you suffer or incur any harm or loss.
        </p>
        <br/> 
        <h2>WE MAKE NO GUARANTEES</h2>
        <br/>
        <p>
          You agree and acknowledge that our Company and Website have made no
          guarantees about earnings or the outcomes of using the service.
          Regardless of whether a service is recommended, we absolutely do not
          make any guarantees as to the results. You understand and agree that
          what works for one person might not work for another person. Moreover,
          results are dependent on many different factors, and we cannot make
          any guarantees. Whether you will succeed or fail is ultimately
          dependent on your own efforts, your particular situation, and numerous
          other circumstances that are beyond our control.
        </p>
        <br/>
        <h2>TESTIMONIAL DISCLAIMER</h2>
        <br/>
        <p>
          Our Site may contain testimonials by actual users of our products
          and/or services. These testimonials reflect the actual user's true and
          own opinions and experiences. We do not pay or manipulate these
          testimonials. They appear on our Website verbatim as given to us by
          the users, except for the correction of grammar or typing errors.
        </p>
        <p>
          However, you agree and understand that the experiences are personal to
          those particular users, and these experiences may not be
          representative of everyone's experience with that product and/or
          service. Your individual results may vary.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
