import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import for navigation

const NotFound = () => {
  const textStyle = {
    color: '#888',
    fontSize: '16px'
  };

  const linkStyle = {
    textDecoration: 'none',
    color: '#007BFF',
    cursor: 'pointer'
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1 style={textStyle}>Oops Page not found.</h1>
      <p style={textStyle}>We can't seem to find the page you're looking for.</p>
      <Link to="/" style={linkStyle}>Home</Link>
    </div>
  );
};

export default NotFound;
