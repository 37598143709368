import React, { useState } from "react";
import { NFTStorage } from "nft.storage";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { BrowserProvider, parseEther } from "ethers";
import { contractAddress, Abi } from "../constant/Contract";
import { Contract } from "ethers";
import { Link } from "react-router-dom";

const NFT_IPFS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJjZGQxNzc0Ni04NGQ4LTQ4MDQtYjA1Ny0zMTFhMjk3Yzg4Y2YiLCJlbWFpbCI6ImZlcnJpc28uYW50aG9ueUBhb2wuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjBkZGE3YjgyYjgxOGFhNjlmMDU0Iiwic2NvcGVkS2V5U2VjcmV0IjoiNWYxZGE4NWUxOTM4Y2QwOGU5M2Y5ODEzYWZiNTRmMjIyZDAyYWVmNzM4YzA1OTJmODVhYjQ3NWJmNzQzZWE1YSIsImlhdCI6MTcxNTU0MDU3OX0.abdsgMqrkVMTB5z35qg_FFj4KKujzvD6iPaQpmsLMAE";

const Modal = ({ isOpen, onClose, drawingImage }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [nftName, setNftName] = useState("");
  const [nftDescription, setNftDescription] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [mintSuccess, setMintSuccess] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const handleUploadAndMint = async () => {
    try {
      console.log(drawingImage, "drawingImage");

      if (!coverImage) return alert("Thumbnail Image Not Uploaded!.");

      if (!videoFile && !drawingImage)
        return alert("Please Upload a Video or Provide a Drawing Image.");

      if (!nftName || !nftDescription) return alert("Please enter the NFT name and description.");

      console.log(drawingImage);

      setIsUploading(true);

      const cidofImage = await UploadToIpfs(coverImage);

      const ipfsURlImage = IpfsUrl(cidofImage);
      console.log("ipfsImage", cidofImage);

      // if (ipfsURlImage) return alert("draw not found");

      let ipfsURlVideo = null;
      if (videoFile) {
        const reader = new FileReader();
        reader.onload = async event => {
          const arrayBuffer = event.target.result;
          const Video = new Blob([arrayBuffer], { type: videoFile.type });
          const cidofVideo = await UploadToIpfs(Video);
          ipfsURlVideo = IpfsUrl(cidofVideo);
          await createAndMintNFT(ipfsURlImage, ipfsURlVideo);
        };
        reader.readAsArrayBuffer(videoFile);
      } else {
        await createAndMintNFT(ipfsURlImage);
      }
    } catch (err) {
      console.log(err);
      setIsUploading(false);
    }
  };

  const createAndMintNFT = async (ipfsURlImage, ipfsURlVideo = null) => {
    const metadata = {
      name: nftName,
      description: nftDescription,
      image: ipfsURlImage,
    };

    if (ipfsURlVideo) {
      metadata.animation_url = ipfsURlVideo;
    }

    const metadataJson = JSON.stringify(metadata);
    const metadataFile = new File([metadataJson], `${nftName}`, {
      type: "application/json",
    });

    const metadataCid = await uploadMetadata(metadataFile);
    console.log("Final Cid", metadataCid);

    setIsUploading(false);

    if (metadataCid) Minting(metadataCid);
  };

  const uploadMetadata = async metadataFile => {
    try {
      const cid = await UploadToIpfs(metadataFile);
      console.log("Metadata uploaded with CID:", cid);
      const ipfsURl = IpfsUrl(cid);
      return ipfsURl;
    } catch (err) {
      console.error("Error uploading metadata:", err);
      setIsUploading(false);
      throw err;
    }
  };

  const Minting = async Finalmetadata => {
    try {
      if (!isConnected) throw Error("User disconnected");
      console.log("On mint", Finalmetadata);
      setIsMinting(true);

      const ethersProvider = new BrowserProvider(walletProvider);
      console.log("On ethersProvider", ethersProvider);
      const signer = await ethersProvider.getSigner();
      console.log("On signer", signer);

      const contract = new Contract(contractAddress, Abi, signer);
      console.log("On Contract", contract);

      if (contract) {
        const tx = await contract.mint(1, [Finalmetadata], {
          value: parseEther("0"),
        });

        await tx.wait();

        alert(tx.hash, "Minting Done!");

        console.log(tx.hash);
        setIsMinting(false);
        setMintSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setIsMinting(false);
    }
  };

  const IpfsUrl = cid => {
    if (cid) {
      const ipfs = `https://gateway.pinata.cloud/ipfs/${cid}`;
      return ipfs;
    }
  };

  const handleVideoChange = e => {
    const file = e.target.files[0];
    if (file.size > 100 * 1024 * 1024) {
      alert("Video must be less than 100MB");
      return;
    }
    setVideoFile(file);
  };

  const UploadToIpfs = async data => {
    try {
      const formData = new FormData();
      formData.append("file", data);

      const res = await fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${NFT_IPFS_TOKEN}`,
        },
        body: formData,
      });

      const resData = await res.json();
      return resData.IpfsHash;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {mintSuccess ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "auto",
            display: isOpen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              maxWidth: "400px",
            }}
          >
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "8px",
              }}
            >
              Congratulations!
            </h2>
            <p>Your NFT is Minted Now. Check your OpenSea account to view it.</p>
            <button
              style={{
                padding: "8px 16px",
                borderRadius: "4px",
                outline: "none",
                backgroundColor: "#28a745",
                color: "white",
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundAttachment: "fixed",
            overflow: "auto",
            zIndex: 50,
            transition: "opacity 300ms",
          }}
          className={`${isOpen && "hidden"}`}
        >
          <div
            style={{
              maxWidth: "600px",
              margin: "20px auto",
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                marginBottom: "8px",
              }}
            >
              Mint Your NFT
            </h2>
            <button
              style={{
                padding: "8px 16px",
                borderRadius: "4px",
                outline: "none",
                backgroundColor: "red",
                color: "white",
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              Close
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <label
                htmlFor="videoUpload"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  outline: "none",
                  display: "inline-block",
                }}
              >
                Upload Video
              </label>
              <input
                type="file"
                id="videoUpload"
                accept="video/mp4, video/webm"
                style={{ display: "none" }}
                onChange={handleVideoChange}
              />

              <label
                htmlFor="coverImage"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  outline: "none",
                  display: "inline-block",
                }}
              >
                {drawingImage
                  ? "Upload your most recently downloaded drawing"
                  : "Upload Cover Image"}
              </label>
              <input
                type="file"
                id="coverImage"
                accept="image/*"
                style={{ display: "none" }}
                onChange={e => setCoverImage(e.target.files[0])}
              />
              <input
                type="text"
                placeholder="Enter NFT name"
                value={nftName}
                onChange={e => setNftName(e.target.value)}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  outline: "none",
                }}
              />
              <textarea
                placeholder="Enter NFT description"
                value={nftDescription}
                onChange={e => setNftDescription(e.target.value)}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  outline: "none",
                  resize: "vertical",
                }}
              />
              <button
                style={{
                  padding: "8px 16px",
                  borderRadius: "4px",
                  outline: "none",
                  backgroundColor: "#28a745",
                  color: "white",
                  cursor: "pointer",
                  marginTop: "8px",
                }}
                onClick={handleUploadAndMint}
                disabled={isUploading || isMinting}
              >
                {isUploading ? "Uploading..." : isMinting ? "Minting..." : "Upload and Mint"}
              </button> 

              <p style={{
                fontSize:"15px"
              }}>Design Your Cover Using a Drawing Tool</p>
            </div>
            <Link
            to="/draw"
            className="mylink"
            style={{
              textDecoration: "none",
              fontSize:"15px",
              border:"1px solid",
              padding:"10px"
            }}
          >
            Drawing  
          </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
