import React, { useState } from "react";
import Modal from "./UploadandMint"; // Assuming Modal component is in a separate file
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

function MainComponent() {
  const [isOpen, setIsOpen] = useState(false); // State for modal visibility
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        {isConnected ? (
          <>
            <button
              onClick={handleOpenModal}
            >
              Upload Now
            </button>
          </>
        ) : (
          <w3m-button />
        )}

        {isOpen && <Modal isOpen={isOpen} onClose={handleCloseModal} />}
      </div>
    </div>
  );
}

export default MainComponent;
