import React from "react";
import eth1 from "../assets/eth1.png";
import eth2 from "../assets/eth2.png";

export default function Like() {
  return (
    <div className="like" id="features">
      <div className="container">
        <div className="content">
          <h2 className="title">CryptoVideos</h2>
          <p className="description">
            At the intersection of creativity and innovation lies the world of
            content creation.
          </p>
          <p className="description">
            CryptVideos empowers you with the exclusive ability to craft any
            content you desire and seamlessly transform it into a unique
            Non-Fungible Token (NFT).
          </p>
        </div>
        <div className="content">
          <h2 className="title">How Its Works</h2>
          <p className="description">
            revolutionary feature not only grants you greater autonomy over your
            content creation process but also revolutionizes how audiences
            engage with your creations.
          </p>
          <div>
            <iframe
              src="https://www.youtube.com/embed/q2RF57B8k-8?si=-_yhdgs57pZQRY0V"
              title="CryptoVideo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
