import React from "react";
import Card from "./Card";
import icon from "../assets/icon.png";
import super1 from "../assets/super2.png";
import release2 from "../assets/draw2.png";
import { Link } from "react-router-dom";

export default function Free() {
  return (
    <div className="free">
      <div className="container">
        <div className="background">
          <div className="ellipse pink"></div>
          <div className="ellipse green"></div>
        </div>
        <div className="content">
          <div className="image">
            <img src={icon} alt="icon" />
          </div>
          <h2 className="title">CryptVideos Free Drawing Tool</h2>
          <p className="description">
            Design your art or cover photos with art tools to make them unique.
          </p>
          <Link to="/draw"
               style={{
                textDecoration: "none",
                fontSize:"15px",
                border:"1px solid",
                padding:"10px",
                maxWidth:"100px",
                color:"white",
                textAlign:"center",
                background:"black"
              }}
          
          >Drawing</Link>
        </div>
        <div></div>
      </div>
      <div className="cards">
        <div className="card1">
          <Card
            image={super1}
            series="Gloop Series"
            title="Purple Man"
            price={2.99}
            tag={12983}
            time={1}
          />
        </div>
        <div className="card2">
          <Card
            image={release2}
            series="Gloop Series"
            title="Purple Man"
            price={3.95}
            tag="1094"
            time={2}
          />
        </div>
      </div>
    </div>
  );
}
